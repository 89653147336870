.cookies-banner {
    background-color: #fff;
    z-index: 12;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0;
    border: 0;
    box-shadow: 0 -5px 15px #00000026;
}

.modal-container {
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 9999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,.4);
}

.modal-botao {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr;
    margin-top: 2.5em;
    color: #424242;
}

.cookies-modal{
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    width: 70%;
    max-width: 930px;
    position: absolute;
    padding: 20px;
    top: 2%;
}

.fechar-modal {
    color: #aaa;
    font-size: 28px;
    font-weight: 700;
}

.cookies-notification {
    margin: 0 5%;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

.cards-container {
    display: flex;
    flex-direction: row;
}

.card-cookies {
    max-width: 280px;
    padding: 1.5em 1em;
    border: 1px solid #979797;
    border-radius: 16px;
    margin: 0 0.5em;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
}

.toggle-cookie {
    display: flex;
    border-top: 1px solid #979797;
    margin-top: 1em;
    padding-top: 1.5em;
}

.label-toggle {
    padding: 0 1em;
}

.title-card {
    margin-bottom: 1.5em;
    font-weight: 500 !important;
    color: #424242;
}

.description-card {
    margin-bottom: 1.5em;
    font-weight: 500;
    color: #424242;
    height: 8em; 
}

.titulo-modal {
    color: #003672;
    font-size: 2.5em;
    margin: 0.5em 0;
}
.subtitulo-modal{
    margin-bottom: 20px;
}

.titulo {
    font-size: 1.3rem;
    display: block;
    margin: 0.5em 0;
}

.description {
    color: #424242;
    text-align: left;
    box-sizing: border-box;

}

.container-flex {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    font-size: 16px;
    gap: 24px;
}

.container-buttons {
    display: flex;
    flex-direction: row;
}

.botao-aceito {
    display: block;
    padding: 8px 0;
    margin: 0 auto 1.5em auto;
    min-width: 150px !important;
    height: 48px;
    text-decoration: none;
    color: #fff;
    text-align: center;
    border-radius: 80px !important;
    box-shadow: 0 5px 15px #005fc89e;
}

.botao-personalizar {
    background-color: #fff !important;
    display: block;
    padding: 8px 0;
    margin: 0 auto 1.5em auto;
    min-width: 150px !important;
    height: 48px;
    text-decoration: none;
    color: #005fc8 !important;
    text-align: center;
    border-radius: 80px !important;
}

.botao-salvar {
    min-width: 100px !important;
    border-radius: 80px !important;
    box-shadow: 0 5px 15px #005fc89e;
}
.botao-cancelar {
    min-width: 100px !important;
    border-radius: 80px !important;
}

.fechar-modal {
    text-align: left;
    border: none;
    background-color: #fff;
}


.link {
    color: #007bff !important;
    text-decoration: none !important;
}
a:hover.link {
    color: #007bff !important;
    font-weight: 300 !important;
    text-decoration:underline !important;
}

@media (max-width: 768px) {
    .container-flex{
        flex-direction: column;
    }
    .cards-container {
        flex-direction: column;
    }

    .cookies-modal {
        width: 100%;
        height: fit-content;
        text-align: center;
        top: 0;
    }

    .fechar-modal{
        text-align: center;
    }

    .titulo-modal {
        font-size: 32px;
    }

    .cards-container {
        align-items: center;
    }

    .card {
        margin: 0 8px 16px;
        min-width: 330px;
    }

    .description-card {
        height: 6em;
    }

    .modal-botao {
        display: block;
        margin-bottom: 20px;
        margin-top: 1.5em;
    }

    .botao-salvar, .botao-cancelar {
        width: 140px;
       
    }
  }