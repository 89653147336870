@font-face {
  font-family: "CAIXA Std";
  src: url("CAIXAStd-Book.woff2") format("woff2"),
    url("CAIXAStd-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "CAIXA Std";
  src: url("CAIXAStd-BookItalic.woff2") format("woff2"),
    url("CAIXAStd-BookItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "CAIXA Std Bold";
  src: url("CAIXAStd-Bold.woff2") format("woff2"),
    url("CAIXAStd-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "CAIXA Std Bold";
  src: url("CAIXAStd-BoldItalic.woff2") format("woff2"),
    url("CAIXAStd-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
