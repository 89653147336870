@font-face {
    font-family: 'ZeppelinIcons', sans-serif;
    src: url('zeppelin-icons.eot?e4wi04');
    src: url('zeppelin-icons.eot?e4wi04#iefix') format('embedded-opentype'), url('zeppelin-icons.ttf?e4wi04') format('truetype'), url('zeppelin-icons.woff?e4wi04') format('woff');
    font-weight: normal;
    font-style: normal;
}

[class^="zepicon-"], [class*=" zepicon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'ZeppelinIcons', sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    line-height: 1em;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.zepicon-arrow-down:before {
    content: "\e900";
}

.zepicon-arrow-left:before {
    content: "\e901";
}

.zepicon-arrow-right:before {
    content: "\e902";
}

.zepicon-arrow-up:before {
    content: "\e903";
}

.zepicon-atendimento:before {
    content: "\e904";
}

.zepicon-auto:before {
    content: "\e905";
}

.zepicon-capitalizacao:before {
    content: "\e906";
}

.zepicon-chat:before {
    content: "\e907";
}

.zepicon-consorcio:before {
    content: "\e908";
}

.zepicon-denuncia:before {
    content: "\e909";
}

.zepicon-download:before {
    content: "\e90a";
}

.zepicon-emprestimo:before {
    content: "\e90b";
}

.zepicon-facebook:before {
    content: "\e90c";
}

.zepicon-faq:before {
    content: "\e90d";
}

.zepicon-google:before {
    content: "\e90e";
}

.zepicon-habitacao:before {
    content: "\e90f";
}

.zepicon-instagram:before {
    content: "\e910";
}

.zepicon-linkedin:before {
    content: "\e911";
}

.zepicon-lupa:before {
    content: "\e912";
}

.zepicon-mensagem:before {
    content: "\e913";
}

.zepicon-moto:before {
    content: "\e914";
}

.zepicon-odonto:before {
    content: "\e915";
}

.zepicon-previdencia:before {
    content: "\e916";
}

.zepicon-residencial:before {
    content: "\e917";
}

.zepicon-sac:before {
    content: "\e918";
}

.zepicon-saude:before {
    content: "\e919";
}

.zepicon-set-down:before {
    content: "\e91a";
}

.zepicon-set-left:before {
    content: "\e91b";
}

.zepicon-set-right:before {
    content: "\e91c";
}

.zepicon-set-up:before {
    content: "\e91d";
}

.zepicon-telefone:before {
    content: "\e91e";
}

.zepicon-twitter:before {
    content: "\e91f";
}

.zepicon-viagem:before {
    content: "\e920";
}

.zepicon-vida:before {
    content: "\e921";
}

.zepicon-youtube:before {
    content: "\e922";
}

.zepicon-check:before {
    content: "\e923";
}

.zepicon-fechar:before {
    content: "\e924";
}

.zepicon-mouse:before {
    content: "\e925";
}