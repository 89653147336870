.nav-page {
    width: 100%;
    height: 65px;
    margin: 0;
    padding: 0;
    display: block;
    top: 90px;
}

.nav-page .nav-page-brand {
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1rem;
    position: relative;
    color: var(--color-neutral-8);
}

.nav-page .nav-page-brand:after {
    content: "";
    display: block;
    position: absolute;
    top: 1.2rem;
    right: -3.399rem;
    border-bottom: 2rem solid;
    border-left: 0 solid transparent;
    border-right: 1rem solid transparent;
    height: 2.2rem;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    width: 4.65rem !important;
    border-bottom-color: var(--color-brand-primary-dark);
}

.nav-page .nav-page-menu-content {
    margin-left: 1.9rem;
}

.nav-page .nav-page-responsive-menu {
    min-width: 50%;
    background-color: var(--color-brand-primary-light);
    height: 50px;
    border-radius: 0;
    border: transparent;
    padding-right: 0;
    padding-left: 0;
}

.nav-page .nav-page-responsive-menu .dropdown-toggle, .nav-page .nav-page-responsive-menu .dropdown-toggle:focus, .nav-page .nav-page-responsive-menu .dropdown-toggle:active, .nav-page .nav-page-responsive-menu .dropdown-toggle:active:focus {
    border: transparent;
    margin: 0;
    background-color: transparent;
    border-radius: 0;
    width: 100%;
    padding: .5rem 0;
    outline: none;
    box-shadow: none;
}

.navbar-toggler.collapsed.nav-page-responsive-menu .dropdown-menu {
    border: 0;
    border-radius: 0;
    margin: 0px;
    top: 46px;
    left: 0px;
    width: 100%;
    background-color: var(--color-brand-primary-light);
}

.nav-page .navbar-collapse {
    background-color: var(--color-brand-primary-light);
    padding-left: 3rem;
    height: 3.65rem;
}

.nav-page .nav-page-menu-content .nav-link, .nav-page .nav-page-menu-content .dropdown-item {
    color: var(--color-neutral-8);
    font-size: 1rem;
    margin: 0 0.7rem;
}