@media all and (max-width: 767px) {
    footer.footer .fale-caixa .nav-link {
        font-size: 18px;
    }

    .cvp-icon-footer {
        width: 21.1px;
        height: 27.43px;
    }

    footer.footer .adress {
        font-size: 11px;
    }

    footer.footer .footer-adress{
        flex-direction: row-reverse!important;
    }
    
}

#cs-button-Fale{
    border-bottom: blue solid 2px;
    border-top: blue solid 2px;
    color: var(--color-brand-primary-dark)
}

.color-button {
    color: var(--color-brand-primary-dark)
}