/* Substitua com o valor apropriado da variável $gray-dark */
.Backdrop {
    background-color: #333; /* Exemplo de valor para $gray-dark */
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    z-index: 1098;
    top: 0;
    left: 0;
}

.spinner {
    position: fixed;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 1099;
}

.spinner .bouncer {
    width: 18px;
    height: 18px;
    margin: 5px;
    background-color: #fff; /* Exemplo de valor para $white */
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bouncer.bounce1 {
    animation-delay: -0.32s;
}

.spinner .bouncer.bounce2 {
    animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        background-color: white;
    }
    40% {
        background-color: blue;
    }
}