@font-face {
  font-family: "cs-icons";
  src: url("cs-icons.eot");
  src: url("cs-icons.eot?#iefix") format("embedded-opentype"), url('cs-icons.woff2') format('woff2'), url("cs-icons.woff") format("woff"), url("cs-icons.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

[data-cs-icon]:before, .cs-icon:before {
  font-family: "cs-icons", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-404:before {
  content: "\61";
}

.icon-adicionar:before {
  content: "\62";
}

.icon-adicionar-m:before {
  content: "\63";
}

.icon-anexar:before {
  content: "\64";
}

.icon-apagar:before {
  content: "\65";
}

.icon-atencao-g:before {
  content: "\66";
}

.icon-atencao-m:before {
  content: "\67";
}

.icon-atencao-p:before {
  content: "\68";
}

.icon-atendimento:before {
  content: "\69";
}

.icon-auto:before {
  content: "\6a";
}

.icon-bloquear:before {
  content: "\6b";
}

.icon-boleto-m:before {
  content: "\6c";
}

.icon-boleto-p:before {
  content: "\6d";
}

.icon-calendario-m:before {
  content: "\6e";
}

.icon-calendario-p:before {
  content: "\6f";
}

.icon-camera-p:before {
  content: "\70";
}

.icon-cap:before {
  content: "\71";
}

.icon-chat:before {
  content: "\72";
}

.icon-conversa:before {
  content: "\73";
}

.icon-check-g:before {
  content: "\74";
}

.icon-check-m:before {
  content: "\75";
}

.icon-check-p:before {
  content: "\76";
}

.icon-compartilhar-p:before {
  content: "\77";
}

.icon-conectar-p:before {
  content: "\78";
}

.icon-conexao:before {
  content: "\79";
}

.icon-configuracoes-p:before {
  content: "\7a";
}

.icon-consorcio:before {
  content: "\41";
}

.icon-credito-p:before {
  content: "\42";
}

.icon-dental:before {
  content: "\43";
}

.icon-desbloquear:before {
  content: "\44";
}

.icon-download:before {
  content: "\45";
}

.icon-editar:before {
  content: "\46";
}

.icon-email-p:before {
  content: "\47";
}

.icon-erro-g:before {
  content: "\48";
}

.icon-erro-p:before {
  content: "\49";
}

.icon-facebook:before {
  content: "\4a";
}

.icon-fechar:before {
  content: "\4b";
}

.icon-formulario:before {
  content: "\4c";
}

.icon-habitacao:before {
  content: "\4d";
}

.icon-hora:before {
  content: "\4e";
}

.icon-imagem:before {
  content: "\4f";
}

.icon-imprimir:before {
  content: "\50";
}

.icon-info-g:before {
  content: "\51";
}

.icon-info-m:before {
  content: "\52";
}

.icon-info-p:before {
  content: "\53";
}

.icon-insta:before {
  content: "\54";
}

.icon-linkar:before {
  content: "\55";
}

.icon-linkedin:before {
  content: "\56";
}

.icon-logout:before {
  content: "\57";
}

.icon-menu:before {
  content: "\58";
}

.icon-menu-acionar:before {
  content: "\59";
}

.icon-menu-busca:before {
  content: "\5a";
}

.icon-menu-duvidas:before {
  content: "\30";
}

.icon-menu-pagamento:before {
  content: "\31";
}

.icon-menu-painel:before {
  content: "\32";
}

.icon-menu-seguro:before {
  content: "\33";
}

.icon-menu-usuario:before {
  content: "\34";
}

.icon-midia:before {
  content: "\35";
}

.icon-pagamento-g:before {
  content: "\36";
}

.icon-pdf:before {
  content: "\37";
}

.icon-pin:before {
  content: "\38";
}

.icon-prev:before {
  content: "\39";
}

.icon-refresh-m:before {
  content: "\21";
}

.icon-refresh-p:before {
  content: "\22";
}

.icon-residencia:before {
  content: "\23";
}

.icon-sair:before {
  content: "\24";
}

.icon-salvar:before {
  content: "\25";
}

.icon-seta-baixo:before {
  content: "\26";
}

.icon-seta-baixo-p:before {
  content: "\27";
}

.icon-seta-cima:before {
  content: "\28";
}

.icon-seta-cima-p:before {
  content: "\29";
}

.icon-seta-direita:before {
  content: "\2a";
}

.icon-seta-direita-p:before {
  content: "\2b";
}

.icon-seta-esquerda:before {
  content: "\2c";
}

.icon-seta-esquerda-p:before {
  content: "\2d";
}

.icon-telefonar:before {
  content: "\2e";
}

.icon-telefone:before {
  content: "\2f";
}

.icon-tempo-m:before {
  content: "\3a";
}

.icon-tempo-p:before {
  content: "\3b";
}

.icon-timeline:before {
  content: "\3c";
}

.icon-triste:before {
  content: "\3d";
}

.icon-twitter:before {
  content: "\3e";
}

.icon-upload:before {
  content: "\3f";
}

.icon-vida:before {
  content: "\40";
}

.icon-visualizar-p:before {
  content: "\5b";
}

.icon-word:before {
  content: "\5d";
}

.icon-youtube:before {
  content: "\5e";
}

.icon-app-store:before {
  content: "\5f";
}

.icon-play-store:before {
  content: "\60";
}