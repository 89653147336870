.menu-dropdown {
    background: var(--color-neutral-7);
}

.header-seguridade .header-nav {
    border-bottom: 1px solid #fff;
}

header .desktop-submenu .nav-link .cvp-action-icon {
    font-size: 1.5rem;
}

header .desktop-submenu .nav-link.icon-menu-custom .cvp-action-icon {
    width: 20px;
}

.logo {
    max-width: 120px;
}