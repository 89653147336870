:root {
  --color-grafite-standard: #3a4859;
  --color-grafite-dark-1: #262f38;
  --color-tangerine-standard: #f39200;
  --color-yellow: #ffb700;
  --color-seguridade-neutral-1: #f7f9fa;
  --color-seguridade-neutral-2: #d0e0e5;
  --color-brand-primary-dark: #0039ba;
  --color-brand-primary-medium: #005ca9;
  --color-brand-primary-light: #005fc8;
  --color-brand-primary-banner: #004790;
  --color-brand-secondary-medium: #e65a00;
  --color-brand-secondary-support-3: #ff7200;
  --color-brand-text-neutral-1: #494949;
  --color-brand-text-neutral-3: var(--color-grafite-standard);
  --color-support-1: #4187d7;
  --color-support-3: #edf4f6;
  --color-support-4: #54bbab;
  --color-support-6: #0066b3;
  --color-support-7: #01c6f4;
  --color-neutral-1: #231f20;
  --color-neutral-4: #a4a5a9;
  --color-neutral-5: #c2c3c6;
  --color-neutral-6: #dbdcde;
  --color-neutral-7: #f0f0f1;
  --color-neutral-8: #ffffff;
  --color-feedback-success-light: #5b8e00;
  --color-feedback-success-medium: #1e7e34;
  --color-feedback-alert-light: #e89800;
  --color-feedback-error-light: #e83131;
  --color-feedback-error-medium: #bd2130;
  --color-feedback-info-light: var(--color-brand-primary-light);
  --color-feedback-info-medium: var(--color-support-1);
  --color-home-blue-background: #164e88;
  --space-size-base: 4px;
  --section-padding: 12px 0px;
  --primary-pill-shadow-color: rgba(0, 95, 201, 0.3);
  --font-brand: "CAIXA Std";
  --font-brand-bold: "CAIXA Std Bold";
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-brand), sans-serif;
  font-size: 16px;
  line-height: 24.4px;
  font-weight: 400;
  color: var(--color-brand-text-neutral-3);
}

@media all and (min-width: 992px) {
  header.header {
    top: -50px !important;
  }
}

header .header-seguridade {
  background: url("../../asserts/images/seguridade-header-background.jpg")
    no-repeat;
  background-size: cover;
  background-position: center;
  align-items: flex-end;
  min-height: 90px;
}

header .header-seguridade .link-back-mobile,
header .header-seguridade .navbar-toggler {
  padding: 0 !important;
  border: 0 !important;
  font-size: 2rem !important;
}

header .header-seguridade .navbar-toggler .nav-brand-icon {
  transform: scale(1.3, 1);
}

header .header-seguridade .navbar-toggler {
  box-shadow: none;
}

@media all and (max-width: 360px) {
  header .header-seguridade .navbar-brand {
    max-width: 54%;
  }
}

@media all and (max-width: 812px) {
  header .header-seguridade .navbar-brand {
    margin: 0 auto;
  }
}

header .header-seguridade .dropdown-menu {
  background: none;
  border: none;
}

header .header-seguridade .nav-link,
header .header-seguridade .dropdown-toggle,
header .header-seguridade .dropdown-menu .dropdown-item {
  color: var(--color-neutral-8) !important;
}

header .header-seguridade .header-search .input-search {
  background: none;
  border: transparent;
  border-radius: 0;
  border-bottom: 1px solid var(--color-neutral-8);
  padding: 0;
  color: var(--color-neutral-8) !important;
}

header .header-seguridade .header-search .input-search:focus {
  border-color: transparent;
  border-bottom: 1px solid var(--color-neutral-8);
  box-shadow: none;
}

header .header-seguridade .header-search .input-search::placeholder {
  color: var(--color-neutral-8);
}

header .header-seguridade .header-search .button-search {
  margin: 0 !important;
  padding: 0 1rem !important;
  background: none;
  border: none;
  font-size: 1.5rem;
}

header .desktop-submenu {
  background: var(--color-neutral-7);
}

header .desktop-submenu .nav-link {
  color: var(--color-brand-primary-light);
  font-family: inherit !important;
}

header .desktop-submenu .nav-link .cvp-action-icon {
  color: var(--color-brand-secondary-support-3);
}

main {
  position: relative;
}

ul,
ol {
  padding-inline-start: 1rem;
}

ul.list,
ol.list {
  margin-bottom: 0;
}

/* Tipografia */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-brand-bold), sans-serif;
}

h1 {
  font-size: 36px;
  line-height: 42.19px;
}

h2 {
  font-size: 25px;
  line-height: 29.3px;
}

h3 {
  font-size: 24px;
  line-height: 28.13px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 19px;
}

h6 {
  font-size: 18px;
  line-height: 28.8px;
}

.text-body-xs {
  font-size: 0.75rem;
}

.text-body-sm {
  font-size: 0.875rem;
}

.text-body-normal {
  font-size: 1rem !important;
}

.text-body-md {
  font-size: 1.125rem;
}

.text-body-lg {
  font-size: 1.5rem;
  line-height: 28.13px;
}

b,
strong {
  font-weight: bold;
}

button.cvp-action,
a.cvp-action.btn {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1rem;
}

.cvp-action.btn.btn-lg:not(.accordion-link-header) {
  font-family: var(--font-brand-bold), sans-serif;
  font-weight: 500;
}

.cvp-action.btn.btn-lg {
  font-size: 16px;
}

button.cvp-action.btn-lg,
a.cvp-action.btn.btn-sm {
  font-size: 0.75rem;
}

.cvp-action.btn .cvp-action-icon.icon-left {
  margin-right: 0.5em;
}

.cvp-action.btn .cvp-action-icon.icon-right {
  margin-left: 0.5em;
}

/* Coloriazação */

/* botões padrão */

.btn.btn-primary {
  background-color: var(--color-brand-primary-light);
  border-color: var(--color-brand-primary-light);
}

.btn.btn-primary:hover {
  background-color: var(--color-brand-primary-dark);
  border-color: var(--color-brand-primary-dark);
}

.btn.btn-outline-primary {
  border-color: var(--color-brand-primary-light);
  color: var(--color-brand-primary-light);
}

.btn.btn-outline-primary:hover {
  background-color: var(--color-brand-primary-light);
  border-color: var(--color-brand-primary-light);
  color: var(--color-neutral-8);
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: var(--color-brand-primary-light);
  border-color: var(--color-brand-primary-light);
  color: var(--color-neutral-8);
}

.btn.btn-secondary {
  background-color: var(--color-tangerine-standard);
  border-color: var(--color-tangerine-standard);
}

.btn.btn-secondary:hover {
  background-color: var(--color-brand-secondary-medium);
  border-color: var(--color-brand-secondary-medium);
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background-color: var(--color-brand-secondary-medium);
  border-color: var(--color-brand-secondary-medium);
}

.btn.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 146, 0, 0.5);
}

.btn.btn-outline-secondary {
  border-color: var(--color-tangerine-standard);
  color: var(--color-tangerine-standard);
}

.btn.btn-outline-secondary:hover {
  background-color: var(--color-tangerine-standard);
  border-color: var(--color-tangerine-standard);
  color: var(--color-neutral-8);
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  background-color: var(--color-tangerine-standard);
  border-color: var(--color-tangerine-standard);
  color: var(--color-neutral-8);
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 146, 0, 0.5);
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(243, 146, 0, 0.5);
}

.btn.btn-success {
  background-color: var(--color-feedback-success-light);
  border-color: var(--color-feedback-success-light);
}

.btn.btn-success:hover {
  background-color: var(--color-feedback-success-medium);
  border-color: var(--color-feedback-success-medium);
}

.btn.btn-warning {
  background-color: var(--color-feedback-alert-light);
  border-color: var(--color-feedback-alert-light);
  color: var(--color-neutral-8);
}

.btn.btn-warning:hover {
  background-color: var(--color-brand-secondary-medium);
  border-color: var(--color-brand-secondary-medium);
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  background-color: var(--color-brand-secondary-medium);
  border-color: var(--color-brand-secondary-medium);
  color: var(--color-neutral-8);
}

.btn.btn-danger {
  background-color: var(--color-feedback-error-light);
  border-color: var(--color-feedback-error-light);
}

.btn.btn-danger:hover {
  background-color: var(--color-feedback-error-medium);
  border-color: var(--color-feedback-error-medium);
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  background-color: var(--color-feedback-error-medium);
  border-color: var(--color-feedback-error-medium);
}

.btn.btn-info {
  background-color: var(--color-feedback-info-light);
  border-color: var(--color-feedback-info-light);
}

.btn.btn-info:hover {
  background-color: var(--color-feedback-info-medium);
  border-color: var(--color-feedback-info-medium);
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  background-color: var(--color-feedback-info-medium);
  border-color: var(--color-feedback-info-medium);
}

.background-neutral {
  background-color: var(--color-neutral-8);
}

.background-dark {
  background-color: var(--color-neutral-1);
}

.background-light {
  background-color: var(--color-neutral-7);
}

.background-transparent {
  background: transparent !important;
}

.cvp-breadcrumb .breadcrumb {
  padding-left: 105px;
  margin-bottom: -1rem;
}

@media all and (max-width: 576px) {
  .cvp-breadcrumb .breadcrumb {
    padding-left: 16px;
  }
}

.cvp-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

.cvp-breadcrumb {
  font-size: 14px;
}

.nav-tabs.page-layout-tab {
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 32px;
}

.nav-tabs.page-layout-tab .nav-item {
  color: var(--color-grafite-dark-1);
  font-size: 20px;
  font-weight: 400;
}

.nav-tabs.page-layout-tab .nav-item:hover {
  text-decoration: none;
}

.nav-tabs.page-layout-tab .nav-item.active {
  color: var(--color-brand-primary-medium);
  border-bottom: 2px solid var(--color-brand-primary-medium);
}

section.page-block {
  padding: 0;
}

section.page-block:last-of-type {
  padding-bottom: 1rem;
}

section.page-block .content-title {
  margin-bottom: 2rem;
}

section.page-block.section-layout-caixa-seguridade:not(.page-banner) {
  padding-right: 1rem;
  padding-left: 1rem;
}

.main-page .section-layout-caixa-seguridade:not(.page-banner) > .container {
  border: 2px solid var(--color-seguridade-neutral-2);
  border-radius: 5px;
  box-shadow: 0px 8px 16px 4px rgba(19, 72, 178, 0.45);
  margin-bottom: 2rem;
}

section.page-block.page-banner {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(100vw / 4);
  padding: 2rem;
  background-position-x: left;
}
@media all and (max-width: 768px) {
  section.page-block.page-banner {
    min-height: 200px;
  }
}
@media all and (max-width: 550px) {
  section.page-block.page-banner {
    min-height: 320px;
  }
}
@media all and (max-width: 320px) {
  section.page-block.page-banner {
    min-height: 250px;
  }
}
@media all and (min-width: 1024px) {
  section.page-block.page-banner {
    min-height: 250px;
  }
  .info-premio {
    border: 2px solid #ffffff;
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    border-radius: 10px;
    color: #ffffff;
    background: rgba(0, 0, 100, 0.2);
    height: 500px;
    margin-bottom: 50px;
    display: grid;
    justify-content: center;
    /* grid-template-columns: repeat(4, 1fr); */

    grid-template-columns: 0.5fr 1fr 0.5fr 1fr;
    grid-template-rows: repeat(5, 1fr);
    align-items: center;

    .details {
      font-size: 32px;
      font-family: "CAIXA Std Bold";
      width: auto;
      max-width: 400px;
    }

    .display-flex-column {
      display: flex;
      flex-direction: column;
    }

    .more-info {
      font-size: 21px;
      margin-top: -76px;
      width: 20rem;
      line-height: normal;
    }
  }

  .title {
    grid-column: 1/5;
    grid-row: 1;
    display: flex;
    justify-content: center;
    margin-top: 30px;

    h1 {
      font-size: 40px;
    }
  }

  .icon-left {
    grid-column: 1;
    grid-row: 2;
    display: flex;
    justify-content: center;
  }

  .desc-left {
    grid-column: 2;
    grid-row: 2;
    display: flex;
    justify-content: center;
    font-family: "CAIXA Std";

    p {
      font-size: 28px;
    }
  }

  .titleSection h1 {
    font-size: 36px;
    padding-top: 32px;
    line-height: normal;
  }

  .titleSectionParcelaReduzida h1 {
    font-size: 36px;
    padding-top: 32px;
    line-height: normal;
    color: #f39200 !important;
    font-style: italic;
  }

  .icon-right {
    grid-column: 3;
    grid-row: 2;
    display: flex;
    justify-content: center;
  }

  .desc-right {
    grid-column: 4;
    grid-row: 2;
    display: flex;
    justify-content: center;
    font-family: "CAIXA Std Bold";
  }

  .left-info {
    grid-column: 2;
    grid-row: 3;
    display: flex;
    justify-content: center;
    font-size: 21px;
  }

  .right-info {
    grid-column: 4;
    grid-row: 3;
    display: flex;
    justify-content: center;
    padding-top: 21px;
  }

  .descriptionCard {
    font-size: 16px !important;
  }

  .line {
    grid-column: 1/5;
    grid-row: 4;
    display: flex;
    justify-content: center;
  }

  .white-horizontal-line {
    border: none;
    height: 3px;
    background-color: white;
    margin: 20px 0;
    width: 30%;
  }

  .luck {
    grid-column: 1/5;
    grid-row: 5;
    column-span: 4;
    display: flex;
    justify-content: center;
    font-size: 32px;
    font-weight: bold;
    font-family: "CAIXA Std Bold";
  }

  .flex-box {
    display: flex;
    flex-direction: row;
  }

  .flex-box-center {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .options-box {
    width: 60%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    color: #ffffff;
    height: auto;
    max-height: 500px;
    margin-bottom: 50px;
    justify-content: center;
    gap: 50px;
  }

  .choose-consortium {
    display: flex;
    color: #ffffff;
    font-size: 40px;
    font-weight: bold;
    padding: 50px;
    justify-content: center;
    align-items: center;
  }

  .card-options-box {
    margin-top: 30px;
    border: 2px solid #3e76cd;
    height: 350px;
    width: 300px;
    background: white;
    color: black;
    margin: 0;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    align-items: center;
    justify-content: space-between;
    padding: 21px 12px;

    h3 {
      font-size: 21px;
      color: #30465b;
    }

    p {
      font-size: 28px;
      text-align: center;
      height: 97.56px;
    }
    svg {
      width: 120px;
    }
  }

  .text-align-center {
    margin: 0 auto;
  }

  .title-sortudao {
    margin: 0 auto;
    font-family: "CAIXA Std Bold";
  }

  .box-info {
    display: flex;
    color: #ffffff;
    font-size: 40px;
    font-weight: bold;
  }

  .background-white {
    background-color: #ffffff;
  }

  .font-color-white {
    color: #ffffff;
  }

  .orange-button {
    background-color: #f29200 !important;
    font-family: "CAIXA Std Bold";
    width: 300px;
    padding: 20px;
    border: none;
    font-size: 18px;
  }
  .orange-button-margin {
    margin-bottom: 100px;
  }

  .blue-button {
    background-color: #015ec6 !important;
    width: 250px;
    padding: 12px;
    border: none;
    font-size: 16px;
    font-weight: bold;
  }

  .font-size-32 {
    font-size: 32px;
  }

  .headers-size {
    h3 {
      font-size: 40px;
    }

    h5 {
      font-size: 32px;
      margin-top: -40px;
    }

    p {
      font-size: 20px;
    }
  }
}
section.page-block.page-banner p {
  margin-bottom: 1rem;
}

.cvp-banners-carousel .carousel-indicators li {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 0.5rem;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #000;
}

section.page-block .section-content.background-image,
.cvp-content-faq {
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;
}

section.page-block p {
  padding: 0;
  margin: 0;
}

p a:not(.btn),
ul a:not(.btn),
ol a:not(.btn) {
  text-decoration: underline;
  cursor: pointer;
  color: inherit;
}

p a:not(.btn):hover,
ul a:not(.btn):hover,
ol a:not(.btn):hover {
  font-weight: bold;
  text-decoration: none;
}

section.page-block:not(.page-banner) p + p,
section.page-block:not(.page-banner) p + ul {
  margin-top: 16px;
}

section.page-block:not(.page-banner) p:last-of-type {
  margin-bottom: 16px;
}

.section-content.faq-content {
  padding: 1.8rem 0;
}

/* ACCODEON */
.cvp-accordion.accordion .card > .card-header .accordion-link-header {
  border: 0;
  outline: none;
  font-size: 16px;
  box-shadow: none;
  color: var(--color-brand-text-neutral-3);
  line-height: normal;
}

/* FAQ */

.accordion-faq.accordion .card {
  border: 0;
  border-radius: 0;
}

.accordion-faq.accordion .card:first-of-type,
.accordion-faq.accordion .card + .card {
  margin-bottom: 0.5rem;
}

.accordion-faq.accordion .card > .card-header {
  background-color: var(--color-neutral-8);
  border-left: 0.385rem solid;
  border-radius: 0;
  padding: 0;
  transition: 0.4s;
  cursor: pointer;
}

.cvp-accordion.accordion-primary .card > .card-header {
  border-left-color: var(--color-brand-primary-light);
}

.cvp-accordion.accordion-secondary .card > .card-header {
  border-left-color: var(--color-tangerine-standard);
}

.accordion-link-header-icon {
  transform: scale(1.5, 1) !important;
}

.accordion-faq.accordion .card .card-body {
  background-color: var(--color-neutral-6);
  border: 0;
}

.cvp-content-faq .faq-fotter-buttons {
  padding: 1rem;
}

/* Tratamentos responsivos  > SM */
@media all and (max-width: 767px) {
  .cvp-content-faq .faq-fotter-buttons.small-button {
    padding: 1.5rem 2.5rem 1rem;
  }

  .info-premio {
    display: grid;
    max-width: 90% !important;
    margin: 0 auto;
    grid-template-rows: repeat(9, 150px);
    border: 2px solid #ffffff;
    border-radius: 10px;
    color: #ffffff;
    background: rgba(0, 0, 100, 0.2);
    align-items: center;
    width: auto;
    height: auto;
    justify-content: center;
    text-align: center;
  }
}

@media all and (max-width: 1024px) {
  .headers-size {
    h3 {
      font-size: 36px;
    }
  }

  .title-sortudao {
    margin: 0 auto;
    font-size: 24px;
    line-height: normal;
    font-family: "CAIXA Std Bold";
  }

  .titleSection h3 {
    color: #fff;
  }

  .luck {
    justify-content: center;
    font-size: 32px;
    font-weight: bold;
    font-family: "CAIXA Std Bold";
  }

  .info-premio {
    display: grid;
    max-width: 70%;
    margin: 0 auto;
    grid-template-rows: 75px 120px 180px 60px 140px 120px 150px 0px 150px;
    border: 2px solid #ffffff;
    border-radius: 10px;
    color: #ffffff;
    background: rgba(0, 0, 100, 0.2);
    align-items: center;
    width: auto;
    height: auto;
    justify-content: center;
    text-align: center;
    svg {
      margin-right: 50px;
    }
  }

  .title {
    grid-row: 1;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .icon-left {
    grid-row: 2;
    display: flex;
    justify-content: center;
  }

  .desc-left {
    grid-row: 3;
    display: flex;
    justify-content: center;
    p {
      font-size: 28px;
    }
  }

  .left-info {
    grid-row: 4;
    display: flex;
    justify-content: center;
  }

  .icon-right {
    grid-row: 5;
    display: flex;
    justify-content: center;
  }

  .desc-right {
    grid-row: 6;
    display: flex;
    justify-content: center;
  }

  .right-info {
    grid-row: 7;
    display: flex;
    justify-content: center;
  }
  .descriptionCard {
    font-size: 21px;
  }

  .line {
    grid-row: 8;
    display: flex;
    justify-content: center;
  }

  .white-horizontal-line {
    border: none;
    height: 3px;
    background-color: white;
    margin: 20px 0;
    width: 100%;
  }

  .details {
    font-size: 32px;
    font-family: "CAIXA Std Bold";
    width: auto;
    max-width: 400px;
  }

  .display-flex-column {
    display: flex;
    flex-direction: column;
  }

  .more-info {
    font-size: 18px;
    margin-top: -76px;
    width: 20rem;
  }

  .choose-consortium {
    display: flex;
    color: #ffffff;
    font-size: 2rem;
    font-weight: bold;
    padding: 50px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .options-box {
    width: 60%;

    margin: 0 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    color: #ffffff;
    margin-bottom: 50px;
    margin-right: 90px;
    justify-content: center;
    gap: 16px;
  }

  .card-options-box {
    margin-top: 30px;
    border: 2px solid #3e76cd;
    height: 350px;
    width: 300px;
    background: white;
    color: black;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    align-items: center;
    padding: 32px;
    margin: 0;
    max-width: 720px;

    justify-content: space-between;

    h3 {
      font-size: 21px;
      color: #30465b;
      text-align: center;
    }

    p {
      font-size: 18px;
      text-align: center;
    }
  }

  .orange-button {
    background-color: #f29200 !important;
    font-size: 14px;
    font-weight: bold;
    width: 300px;
    padding: 20px;
    border: none;
    margin-bottom: 40px;
  }

  .blue-button {
    background-color: #015ec6 !important;
    width: 200px;
    padding: 12px;
    border: none;
    font-size: 18px;
    font-weight: bold;
  }
}

.cvp-accordion.accordion-faq.accordion-seguridade-light.accordion .card {
  margin-bottom: 0;
}

.accordion-faq.accordion-seguridade-light.accordion .card > .card-header {
  border-left: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--color-seguridade-neutral-2) !important;
  margin-bottom: 0;
}

.accordion-faq.accordion-seguridade-light.accordion .card .card-body {
  background-color: var(--color-seguridade-neutral-1);
}

/* FIM ACCORDEON */

.footer {
  background: linear-gradient(
    179.6deg,
    var(--color-brand-primary-medium) 0.35%,
    var(--color-support-4) 113.36%
  );
  background-position: center;
  margin-top: -2px;
}

.footer .footer-section {
  padding: 20px 0;
}

.footer .fale-conosco-footer {
  background-color: var(--color-brand-primary-light);
  width: 100%;
}

.footer .footer-section + .footer-section {
  border-top: 1px solid var(--color-support-3);
}

.footer .footer-section.footer-socials .footer-socials-itens {
  padding: 1rem 0;
}

.footer .footer-section.footer-socials a {
  padding: 0;
  font-size: 3rem;
  line-height: 0;
}

.footer .footer-section.footer-socials a.secured-link {
  margin-right: 1.5rem;
}

.footer .footer-section.footer-socials a.icon-app {
  font-size: 1.9rem;
  padding: 0.5rem;
  margin-right: 1rem;
}

.footer .footer-section.footer-socials .cs-icon,
.footer .footer-section.footer-socials h6 {
  color: var(--color-neutral-8);
}

.footer address {
  margin: 0;
}

.footer address > p,
.footer .copyright {
  font-size: 10px;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--color-neutral-8);
}

footer.footer .menu-overlay .nav-link {
  text-decoration: underline;
}

footer.footer .menu-overlay .nav-link:hover {
  text-decoration: none;
  font-weight: bold !important;
}

/* --- */

.sinalizador-titulo::after {
  content: "";
  border-bottom: 4px solid var(--color-brand-primary-light);
  display: block;
  width: 24px;
  padding-top: 5px;
}

.info-duvida-main-cuidado-futuro-color-primary p,
.info-duvida-main-cuidado-futuro-color-primary h5 {
  color: var(--color-brand-primary-light) !important;
}

.info-duvida-main-cuidado-futuro-color-primary {
  border-left: 3px solid var(--color-brand-primary-light) !important;
}

.info-duvida-main-cuidado-familia-color-primary p,
.info-duvida-main-cuidado-familia-color-primary h5 {
  color: var(--color-brand-primary-light) !important;
}

.info-duvida-main-cuidado-familia-color-primary {
  border-left: 3px solid var(--color-brand-primary-light) !important;
}

.info-duvida {
  padding: 0.5em;
  margin: 0.5em 0 0.5em 0;
}

.page-block.page-error .error-template {
  padding: 40px 15px;
  text-align: center;
}

.page-block.page-error .error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}

.page-block.page-error .error-actions .btn {
  margin-right: 10px;
}

.image-background-full {
  background-size: cover !important;
}

.button-color-attencion {
  background-color: var(--color-tangerine-standard) !important;
  color: var(--color-neutral-8);
}

.cvp-general-conditions-controls {
  padding: 16px;
  border: 1px solid var(--color-neutral-7);
}

.cvp-general-conditions-controls .form-group {
  margin: 1rem;
}

.cvp-general-conditions-download {
  border: 1px solid var(--color-neutral-7);
  margin-top: -1px;
}

.conheca-amparo-grid {
  padding: 22px 36px;
  /* padding-right: 80px; */
  box-sizing: border-box;
}

.conheca-amparo-grid .cvp-card {
  border-radius: 0 !important;
  padding: 36px 24px 1px !important;
}

.conheca-amparo-grid .conheca-amparo-title h2 {
  max-width: 244px;
  font-size: 18px;
}

.cvp-comprar-um-de-nossos-produtos h3 {
  text-align: center;
}

.cvp-general-conditions-download .download-icon {
  font-size: 4rem;
  text-align: center;
  margin: 0 16px;
  color: var(--color-brand-primary-medium);
}

.cvp-general-conditions-download small {
  display: block;
  padding: 1rem;
}

/* BUTTONS */

.cvp-action {
  cursor: pointer;
}

.cvp-action.btn:not(.nav-link):not(.navbar-brand) {
  padding: 1rem;
}

.cvp-action.btn.btn-pill {
  min-width: 60px;
}

.cvp-action.btn-btn-transparent,
.cvp-action.btn-phantom {
  background-color: transparent !important;
}

.cvp-action.btn-phantom {
  color: var(--color-neutral-8) !important;
  border-color: var(--color-neutral-8) !important;
}

.cvp-action.btn:not(.btn-block):not(.nav-link):not(.navbar-brand) {
  margin: 0.5rem;
}

.cvp-action.btn:not(.btn-block):not(.nav-link):not(.navbar-brand):not(.btn-pill):not(.navbar-toggler):not(.button-search) {
  min-width: 175px;
}

.cvp-action.btn-pill.btn-primary:not(.btn-phantom) {
  -webkit-box-shadow: 0px 8px 16px 0px var(--primary-pill-shadow-color);
  -moz-box-shadow: 0px 8px 16px 0px var(--primary-pill-shadow-color);
  box-shadow: 0px 8px 16px 0px var(--primary-pill-shadow-color);
}

.cvp-action.btn-rounded {
  border-radius: 44px;
  min-width: 8rem;
}

/* Alertas */

.cvp-alert.alert-primary {
  background-color: var(--color-brand-primary-light);
  color: var(--color-neutral-8);
}

.cvp-alert.alert-secondary {
  background-color: var(--color-tangerine-standard);
  color: var(--color-neutral-8);
}

.cvp-alert.alert-info {
  background-color: var(--color-support-1);
  color: var(--color-neutral-8);
}

.cvp-super-dica .cpv-super-dica-title {
  color: var(--color-neutral-8);
}

.bg-main-cuidado-futuro-color-primary {
  background-color: var(--main-cuidado-futuro-color-primary);
}

.bt-main-cuidado-familia-color-primary {
  border-top: 5px solid var(--main-cuidado-familia-color-primary) !important;
}

.mb-15 {
  margin-bottom: 15em;
}

.cvp-vida-conheca-amparo {
  margin-bottom: 34px;
}

.cvp-vida-conheca-amparo .cvp-subtitulo {
  line-height: 28.8px !important;
}

#condicoes-gerais {
  padding-bottom: 16px;
}

.cvp-vida-bloco-o-que-oferecemos p {
  color: var(--color-neutral-4) !important;
}

.cvp-text-monogromatico-400 {
  color: var(--color-neutral-4) !important;
}

.c-primary {
  color: var(--color-brand-primary-light) !important;
}

#entenda-vida .container p {
  max-width: 670px;
}

/* Tratamentos responsivos XS */

@media all and (max-width: 576px) {
  .background-image-none-xs {
    background-image: none !important;
  }
}

/* Tratamentos responsivos SM */

@media all and (max-width: 767px) {
  #entenda-vida .container {
    padding: 0 31px 23px 45px !important;
    box-sizing: border-box;
  }
  .cvp-benefits .container {
    padding: 0 !important;
  }
  .cvp-benefits {
    padding: 0;
    box-sizing: border-box;
  }
  .cvp-vida-comprar-online {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .cvp-vida-conheca-amparo .container {
    padding: 30px 18px 45px 29px !important;
  }
  .conheca-amparo-grid {
    padding: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
  .conheca-amparo-grid .cvp-card {
    border-radius: 0 !important;
    padding: 36px 24px 1px !important;
  }
  .conheca-amparo-grid .conheca-amparo-title {
    grid-area: title;
    min-width: 100%;
    margin-right: 0 !important;
    margin-bottom: 32px;
  }
  .conheca-amparo-grid .conheca-amparo-title h2 {
    max-width: 100%;
    margin-bottom: 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
  }
  .cvp-selecione-produto-draw-result {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -90%);
  }
  .background-image-none-sm {
    background-image: none !important;
  }
  .h1-sm {
    font-size: 24px !important;
    line-height: 28.13px !important;
  }
}

/* Tratamentos responsivos  > SM */

@media all and (min-width: 767px) {
  .page-banner {
    min-height: 338px;
  }
  .cvp-sua-empresa-pode-contar-com-gente .cvp-btn-acessar-area-cliente {
    width: 280px !important;
  }
}

/* Tratamentos responsivos MD */

@media all and (max-width: 991px) {
  .background-image-none-md {
    background-image: none !important;
  }
}

/* Tratamentos responsivos LG */

@media all and (max-width: 1199px) {
  .faq-fotter-buttons .btn {
    width: 100%;
  }
  .background-image-none-lg {
    background-image: none !important;
  }
}

.min-height-73 {
  min-height: 73px;
}

.draw-number {
  font-size: 30px;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
}

.cvp-form-control {
  font-family: "CAIXA Std", sans-serif;
  font-size: 14px;
  position: relative;
}

.cvp-form-control select {
  padding: 0.5rem 1rem;
  min-height: 56px;
}

.cvp-form-control input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.cvp-form-control input[type="number"] {
  -moz-appearance: textfield;
}

.cvp-form-control select + .cvp-select-icon {
  position: absolute;
  top: 15px;
  right: 20px;
  color: var(--color-brand-text-neutral-1);
  height: 100%;
  transform: scale(1.2, 1);
}

.cvp-icon-banner {
  margin-right: 0.825rem;
  color: var(--color-brand-secondary-medium);
  min-width: 30px;
  min-height: 30px;
  position: absolute;
  left: -32px;
}

.cvp-icon-banner path.custom-icon {
  fill: var(--color-brand-secondary-medium);
}

.cvp-icon-heart-banner-para-seu-negocio {
  margin-top: -30px;
}

.cvp-w-24 {
  width: 24px;
}

.cvp-color-brand-primary-light {
  color: var(--color-brand-primary-light);
}

.cvp-bg-color-neutral-5 {
  background-color: var(--color-neutral-5);
}

.cvp-bg-color-color-brand-primary-dark {
  background-color: var(--color-brand-primary-dark);
}

/* MYSC */

[class*="border-"] {
  border-color: var(--color-grafite-standard) !important;
}

.text-brand {
  color: var(--color-brand-primary-medium) !important;
}

.text-brand-secondary {
  color: var(--color-brand-secondary-support-3) !important;
}

/* END MYSC */

footer.footer .menu-overlay.menu-conosco .nav-link {
  color: var(--color-neutral-8);
}

footer.footer .menu-overlay .nav-seguridade-socials .nav-link:hover {
  text-decoration: none;
}

.cvp-draw-result-controls {
  border: 2px solid var(--color-seguridade-neutral-2);
  border-radius: 5px;
  box-shadow: 0px 8px 16px 4px rgba(19, 72, 178, 0.45);
  padding: 2rem;
}

.cvp-general-conditions .cvp-download-link {
  font-weight: 500;
  font-size: 16px;
  line-height: 18.75px;
  color: var(--color-brand-primary-dark);
}

.cvp-download-link .cvp-download-link-label {
  text-decoration: underline;
}

.cvp-download-link .cvp-download-link-icon {
  font-size: 3rem !important;
  text-decoration: none !important;
}

.cvp-download-link:hover,
.cvp-download-link:hover .cvp-download-link-label {
  text-decoration: none !important;
  font-weight: bold;
}

.center-image > svg,
.center-image > img,
.center-image > .img {
  width: 100%;
  height: auto;
}

.bloco-apresentacao,
.cvp-benefits {
  padding: 1.5rem;
  box-sizing: border-box;
}

.image-100 {
  height: 150px !important;
  text-align: center;
}

.fazer-uma-compra {
  padding-top: 40px;
  padding-bottom: 40px;
}

.fazer-uma-compra .fazer-uma-compra-body {
  padding-bottom: 2rem;
}

.cvp-date-picker {
  position: relative;
}

.cvp-date-picker .cvp-date-picker-icon {
  position: absolute;
  top: 2.4rem;
  right: 0.5rem;
  z-index: 1;
  width: 1rem;
  cursor: pointer;
}

.blocks-content .cc-card-section .blocks-content-column {
  padding-bottom: 2rem;
}

.blocks-content .cc-card-section .blocks-content-column-content {
  border: 2px solid var(--color-seguridade-neutral-2);
  border-radius: 5px;
  box-shadow: 0px 8px 16px 4px rgba(19, 72, 178, 0.45);
  height: 100%;
  padding-bottom: 1rem;
}

.blocks-content
  .cc-card-section
  .blocks-content-column-content
  .cvp-card-image {
  height: initial;
}

.cota-control .cota-remove-icon {
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  cursor: pointer;
  color: var(--color-brand-primary-light);
}

.cvp-float-chat {
  position: sticky;
  bottom: 0;
  margin-left: auto;
  margin-right: 0.5rem;
  width: 200px;
  padding: 0.5rem 1rem;
}

.cvp-float-chat .cvp-float-chat-button {
  margin: 1rem !important;
  padding: 0.5rem !important;
}

.cvp-float-chat .cvp-float-chat-button .cvp-float-chat-icon {
  margin-right: 0.5rem;
}

.cvp-float-chat .cvp-float-chat-button::before {
  border: 10px solid transparent;
  border-top: 5px solid var(--color-brand-primary-light);
  border-bottom: 0;
  height: 0;
  width: 0;
  border-top-width: 15px;
  content: "";
  display: block;
  position: absolute;
  right: 15px;
  top: 56%;
  transform-origin: center;
  transform: translateY(16.6666666667px);
  z-index: 1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.cvp-float-chat .cvp-float-chat-button:focus {
  box-shadow: none !important;
}

.cvp-float-chat .cvp-float-chat-button:hover:before,
.cvp-float-chat .cvp-float-chat-button:focus:before {
  border-top-color: var(--color-feedback-info-medium);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.focus-shadow-none:focus {
  box-shadow: none !important;
}

.background-sortudao {
  background-image: url("../../asserts/images/background-sortudao.png");
  background-size: cover;
}


.card-parcela-reduzida {
  background-color: var(white);

  h5 {
    color: #606060;
  }

  p {
    color: #606060;
  }
}

.background-parcela-reduzida {
  background-color: #fb75b3;
}

.sac {
    display: block;
    width: auto;
    height: calc(1.5em + .75rem + 2px);
    padding: .5rem 1rem;
    min-height: 56px;
    min-width: 300px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.highlight{
  color: #F36800;
  font-weight: bold;
}

.flex-center{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

button.sc-beySPh {
  border-bottom-right-radius: 0px !important;
  visibility: hidden !important;
}

div.sc-jTQCzO {
  top: 40%;
}
