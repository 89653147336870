.cvp-card {
    padding: 0.5rem;
    text-align: center;
}

.cvp-card .cvp-card-header {
    margin-bottom: 1rem;
}

.cvp-card .cvp-card-image {
    margin-bottom: 1rem;
    height: 120px;
}