.custom-section {
  padding: 0 !important;
}

.custom-section .container-fluid {
  padding: 0;
}

.content-404 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.content-404 h1 {
  font-size: 24px;
  text-align: center;
  color: #494949;
  margin-bottom: 50px;
}

.content-404 .back {
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #424242;
  margin-top: 50px;
}

.content-404 .back a {
  color: #0773D7;
  text-decoration: underline;
}

.content-404 .back a:hover {
  color: #0773D7;
  text-decoration: underline;
  font-weight: normal;
}