:root {
  --header-table-color: var(--color-brand-primary-light);
  --body-table-color: #b0d2f8;
}

.cc-primeiro-passo > .container {
  background-color: var(--color-home-blue-background);
  color: var(--color-neutral-8);
}

@media all and (min-width: 768px) {
  .cc-fique-atento > .container {
    padding-left: 0px !important;
  }
}

@media all and (min-width: 768px) {
  .cc-farq-saiba-mais > .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media all and (min-width: 768px) {
  .cc-farq-ja-sou > .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

#ja-sou-cliente .tabela-tarifas thead th {
  border-top: none !important;
}

#ja-sou-cliente .tabela-tarifas thead th span.descricao-subtitulo {
  margin-left: 5px;
  font-weight: 500;
  font-size: 12px;
}

#ja-sou-cliente .tabela-tarifas thead tr {
  background-color: var(--header-table-color);
  color: var(--color-neutral-8);
}

#ja-sou-cliente .tabela-tarifas tbody tr {
  background-color: var(--body-table-color) !important;
}

#ja-sou-cliente .tabela-tarifas tbody tr:nth-of-type(odd) {
  background-color: transparent !important;
}

#ja-sou-cliente .tabela-tarifas tbody tr td {
  vertical-align: middle;
  font-size: 14px;
}

#ja-sou-cliente .tabela-tarifas tbody tr td.descricao-valor {
  width: 30%;
}

#ja-sou-cliente .tabela-tarifas tbody tr td .item-description-light {
  color: var(--color-neutral-4);
}

.cc-button-cartilha {
  border-radius: 0px;
  padding: 16px, 36px, 16px, 36px;
  flex-direction: column;
}

#proximas-assembleias .data-assembleia {
  font-weight: 700;
  color: var(--color-support-6);
  border: 1px solid var(--color-support-6);
  padding: 1rem 0.5rem;
  text-align: center;
}

#proximas-assembleias .cvp-action {
  margin-bottom: 1.5rem;
}

.resultado-assembleia .card-cota {
  border: 2px solid var(--color-seguridade-neutral-2);
  box-sizing: border-box;
  box-shadow: 0px 8px 16px 4px rgba(19, 73, 178, 0.45);
  border-radius: 5px;
  height: 252px;
  min-height: 252px;
}

@media all and (max-width: 1023px) {
  .resultado-assembleia .card-cota.lance-livre .cvp-card-header {
    margin-bottom: 0 !important;
  }
}

.resultado-assembleia .numeros-federal {
  font-family: var(--font-brand-bold), sans-serif;
}

.resultado-assembleia .lista-card-cota-title {
  position: relative;
  border-bottom: 1px solid var(--color-seguridade-neutral-2);
}

.resultado-assembleia .lista-card-cota-title h6 {
  max-width: 200px;
  text-align: center;
  position: absolute;
  background-color: var(--color-neutral-8);
  top: 0;
}

.resultado-assembleia .card-cota .cvp-card-footer .cvp-action.ver-mais {
  padding: 0;
  font-size: 14px;
}

.resultado-assembleia .card-cota .cvp-card-footer .cvp-action.ver-mais:focus {
  box-shadow: none;
  text-decoration: none !important;
}

.resultado-assembleia .card-cota .cvp-card-footer .cvp-action .cs-icon {
  font-size: 1.4rem;
}

.resultado-assembleia .card-cota .cvp-card-footer .cvp-action:hover {
  text-decoration: none !important;
}

.resultado-assembleia .card-cota .numero-cota,
.cc-modal.modal-cotas .numero-cota {
  border-bottom: 5px solid var(--color-brand-secondary-support-3) !important;
}

.cc-modal.modal-cotas .modal-content {
  border-top: 7px solid var(--color-brand-secondary-support-3);
  border-radius: 0;
}

.cc-modal.modal-cotas .modal-content .modal-header {
  border-bottom: none;
  display: flex;
  justify-content: center;
}

.cc-modal.modal-cotas .modal-content .modal-header .modal-title {
  font-family: var(--font-brand), sans-serif;
  line-height: 28px;
  color: var(--color-brand-secondary-support-3);
}

.cc-modal.modal-cotas .modal-content .modal-body {
  min-height: 200px;
}

.cc-modal.modal-cotas .modal-content .modal-body .cota-items {
  overflow-y: auto;
}

.cc-modal.modal-cotas .numero-cota {
  min-width: 105px;
}

.cc-modal.modal-cotas .busca-cotas .input-group-append {
  position: relative;
  top: 0.75rem;
  left: -1.5rem;
  cursor: pointer;
  z-index: 3;
}

.cc-modal.modal-cotas .modal-content .modal-footer {
  justify-content: center;
  border-top: none;
}

.cc-modal.percentual-contemplacao .modal-content {
  border-radius: 0;
  width: 271px;
}

.cc-modal.percentual-contemplacao .modal-content .modal-header {
  border-bottom: none;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.cc-modal.percentual-contemplacao .modal-content .modal-header .close {
  border-bottom: none;
  padding: 0 1rem;
  font-size: 1rem;
}

.cc-modal.percentual-contemplacao .modal-content .modal-header .close,
.cc-modal.percentual-contemplacao .modal-content .modal-header h4 {
  color: var(--color-brand-primary-medium);
}

.cc-modal.percentual-contemplacao .modal-content .modal-header .close:focus {
  outline: none;
}

.cc-modal.percentual-contemplacao .modal-content .modal-header .close:hover {
  font-size: 1.25rem;
}

.mobile {
  display: block;
}
.desktop {
  display: none;
}
.banner-mobile-text-container {
  max-width: 200px;
  position: absolute;
  top: 100px;
}

.button-black-friday {
  color: black;
  margin-top: 10px !important;
  margin-left: 0 !important;
  padding: 5px 0 !important;
  font-weight: bold;
}

@media all and (max-width: 1025px) {
  .banner-ir .offset-lg-4 {
    margin-left: 38%;
  }
}

@media all and (max-width: 550px) {
  .banner-vpe {
    align-items: flex-end;
    background-position: center !important;
  }
  .banner-vpe .offset-lg-4 {
    margin-left: 0;
  }
}

@media all and (min-width: 768px) {
  #blocos-valores-credito .container {
    padding-right: 0;
    padding-left: 0;
  }
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
  .banner-mobile-text-container {
    position: initial;
  }
}
@media all and (max-width: 320px) {
  .banner-mobile-text-container {
    left: -30px;
  }
}

@media all and (max-width: 320px) {
  .banner-mobile-text-container {
    left: -30px;
  }
}

image-100{
  height: 100%;
}

.input-group {
  display: flex;
  flex: 1;
  margin-bottom: 1rem
}

p.contract-error{
  color: rgb(255, 32, 32);
  font-size: 0.8rem;
}

.sortudao{
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  &:focus {
    border-color: black;
    outline: none;
  }
}