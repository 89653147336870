@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-200px);
    transform: translateX(-200px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0px);
  }
}

.accessibilityButton {
  cursor: pointer;
  position: fixed;
  top: 50%;
  left: 0;
  text-align: center;
  z-index: 1000;
}

.accessibilityItem {
  width: 48px;
  height: 48px;
}

.accessibilityItem:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.accessibilityModal {
  display: flex;
  flex-direction: column;
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#000, 0.7);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  justify-content: flex-start;
}

.accessibilityModalHeader {
  background-color: #0065b7;
  color: white;
  width: 100%;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.accessibilityModalTitle {
  margin: 0;
  padding: 12px 24px;
  position: relative;
  top: 2px;
  font-size: 20px;
}

.accessibilityModalContent {
  display: flex;
  border-radius: 0 12px 12px 0;
  background-color: #f9f9f9;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 354px;
  height: 200px;
  top: 50%;
  animation: slide-right 0.3s both;
}
.accessibilityModalContent svg {
  max-width: 250px;
  width: 48px;
  min-height: 48px;
}

.closeButton {
  font-size: 32px;
  cursor: pointer;
  z-index: 999;
  padding: 0 12px 8px 0;
}

.titleModal {
  font-size: 26px;
  font-weight: 700;
}

.accessibilityCards {
  display: flex;
  gap: 12px;
  align-content: center;
  justify-content: center;
}

.accessibilityCard {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  width: 95px;
  height: 115px;
  flex-direction: column;
  border-radius: 8px;
  font-size: 12px;
  color: #0065b7;
  background: #fff;
  border: 1px solid #0065b7;
  box-shadow: 0px 4px 4px 0px #00000040;
  line-height: normal;
  padding: 8px 4px 4px 4px;
  text-decoration: none;
}

.accessibilityCard p {
  font-size: 16px;
}
