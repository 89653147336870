.sustainability-modal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  width: 70%;
  max-width: 930px;
  position: absolute;
  padding: 20px;
  top: 30%;
  border-radius: 8px;
  align-items: center;
}

.container-button {
  position: relative;
  left: 50%;
  bottom: 18px;
}

.fechar-modal-sustainability {
  flex: 0 0 auto;
  text-align: right;
  border: none;
  background-color: #fff;
  color: #aaa;
  font-size: 28px;
  font-weight: 700;
  display: inline-block;
  width: auto;
  height: auto;
  padding: 5px;
  cursor: pointer;
}

.modal-container {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 9999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.titulo-sustainability-modal {
  font-family: "CAIXA Std";
  color: #545f6c;
  font-size: 1.3em;
  margin: 0.5em 0;
  display: inline-block;
  text-align: center;
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .sustainability-modal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    width: 70%;
    max-width: 930px;
    position: absolute;
    padding: 20px;
    top: 40%;
    border-radius: 8px;
    align-items: center;
  }

  .container-button {
    position: relative;
    left: 50%;
    bottom: 18px;
    margin-bottom: -20px;
  }

  .titulo-sustainability-modal {
    font-family: "CAIXA Std";
    color: #545f6c;
    font-size: 1em;
    margin: 0.5em 0;
    display: inline-block;
    text-align: center;
    margin-bottom: 30px;
  }

  .subtitulo-sustainability-modal {
    margin-bottom: 20px;
    text-align: left;
    justify-content: space-between;
    font-weight: bold;
    font-family: "CAIXA Std Bold";
    font-size: 15px;
    color: #005fc8;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding-bottom: 2px;
    width: 68%;
  }
}

.subtitulo-sustainability-modal {
  margin-bottom: 20px;
  text-align: left;
  justify-content: space-between;
  font-weight: bold;
  font-family: "CAIXA Std Bold";
  color: #005fc8;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 2px;
  width: 68%;
}
